<template>
<v-container fluid>
<v-row>
    <v-col cols="12" md="6">
        <v-card :class="uiParams.cardElevation + ' rounded-bl-xl pb-1'" tile flat >
        <v-toolbar flat color="#f6f8f8" prominent height="50px">
            <v-toolbar-title>
                <v-icon color="red">mdi-alert</v-icon>
                Alertes Urgentes
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="testEndpoint()"><v-icon>mdi-restore</v-icon></v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-list class="pb-3" v-if="alerts.length">
            <template v-for="(item, index) in alerts">
                <v-list-item v-if="item.eve_typ_id == 'ALT01'" :key="index" class="pb-0" :href="(item.tre_lat) ? '/#/map/center/' + item.tre_lat + ',' + item.tre_lng : '/#/dashboard/sheet/' + item.cad_uid + '/layer/' +  item.cad_lra_id + '/type/' + item.cad_typ_id_shape">
                    <v-list-item-content>
                        <v-list-item-title :style="'color:' + item.sta_color + ';'">
                            <span class="caption grey--text">{{ (item.eve_title) ? item.eve_title : item.eve_code }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="caption font-italic">{{ item.eve_custom_fields.eve_description }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <span class="caption">{{ locDateFormat.formatLocale(item.eve_date_start, 'fr') }}</span>
                        <v-chip small label outlined>{{ typeState[item.eve_typ_id.substring(0,3)][item.eve_typ_id].typ_name }}</v-chip>
                    </v-list-item-action>
                </v-list-item>
                <v-divider :key="'D-' + index"></v-divider>
            </template>
        </v-list>
        <v-alert v-else color="warning" class="ma-2" icon="mdi-alert">Aucune alerte</v-alert>
        </v-card>
    </v-col>
    <v-col cols="12" md="6">
        <v-card :class="uiParams.cardElevation + ' rounded-bl-xl pb-1'" tile flat >
        <v-toolbar flat color="#f6f8f8" prominent height="50px">
            <v-toolbar-title>
                <v-icon color="blue">mdi-alert</v-icon>
                Alertes standard
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon><v-icon>mdi-restore</v-icon></v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-list class="pb-3" v-if="alerts.length">
            <template v-for="(item, index) in alerts">
                <v-list-item v-if="item.eve_typ_id == 'ALT02'" :key="index" class="pb-0" :href="(item.cad_uid) ? '/#/dashboard/sheet/' + item.cad_uid + '/layer/' +  item.cad_lra_id + '/type/' + item.cad_typ_id_shape : '/#/map/'">
                    <v-list-item-content>
                        <v-list-item-title :style="'color:' + item.sta_color + ';'">
                            <span class="caption grey--text">{{item}}{{ (item.eve_title) ? item.eve_title : item.eve_code  }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="caption font-italic">{{ item.eve_custom_fields.eve_description }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <span class="caption">{{ locDateFormat.formatLocale(item.eve_date_start, 'fr') }}</span>
                        <v-chip small label outlined>{{ typeState[item.eve_typ_id.substring(0,3)][item.eve_typ_id].typ_name }}</v-chip>
                    </v-list-item-action>
                </v-list-item>
                <v-divider :key="'D-' + index"></v-divider>
            </template>
        </v-list>
        <v-alert v-else color="info" class="ma-2" icon="mdi-alert">Aucune alerte</v-alert>
        </v-card>
    </v-col>
    <v-col cols="12" md="6">
        <v-card :class="uiParams.cardElevation + ' rounded-bl-xl pb-1'" tile flat >
        <v-toolbar flat color="#f6f8f8" prominent height="50px">
            <v-toolbar-title>
                <v-icon >mdi-calendar-multiple-check</v-icon>
                Dernière taches
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon><v-icon>mdi-restore</v-icon></v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-list class="pb-0" two-line v-if="events.length">
            <template v-for="(item, index) in events">
                <v-list-item :key="index" class="pb-0" :href="(item.tre_lat) ? '/#/map/center/' + item.tre_lat + ',' + item.tre_lng : '/#/dashboard/sheet/' + item.cad_uid + '/layer/' +  item.cad_lra_id + '/type/' + item.cad_typ_id_shape">
                    <v-list-item-content>
                        <v-list-item-title :style="'color:' + item.sta_color + ';'">
                            <span class="caption grey--text">{{ (item.eve_title) ? item.eve_title : item.eve_code  }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="caption font-italic">{{ item.eve_custom_fields.eve_description }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <span class="caption">{{ locDateFormat.formatLocale(item.eve_date_start, 'fr') }}</span>
                        <v-chip small label outlined :href="'/#/dashboard/sheet/' + item.cad_uid + '/layer/' +  item.cad_lra_id + '/type/' + item.cad_typ_id_shape"
                            :color="typeState[item.eve_typ_id.substring(0, 3)][item.eve_typ_id].typ_color">{{ typeState[item.eve_typ_id.substring(0, 3)][item.eve_typ_id].typ_name }}</v-chip>
                    </v-list-item-action>
                </v-list-item>
                <v-divider :key="'D-' + index"></v-divider>
            </template>
        </v-list>
        <v-alert v-else color="info" class="ma-2" icon="mdi-alert">Aucune tache</v-alert>
        </v-card>
    </v-col>
    <v-col cols="12" md="6">
        <v-card :class="uiParams.cardElevation + ' rounded-bl-xl pb-1'" tile flat >
        <v-toolbar flat color="#f6f8f8" prominent height="50px">
            <v-toolbar-title>
                <v-icon>mdi-account-multiple-outline</v-icon>
                Liste des contacts
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon><v-icon>mdi-restore</v-icon></v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-list class="pb-3" v-if="contacts.length">
            <template  v-for="(item, index) in contacts">
                <v-list-item :key="index" class="pb-2" v-if="item.ctt_typ_id == 'CTT01'">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.ctt_firstname }} {{ item.ctt_lastname }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <span class="caption mr-2">{{ item.ctt_mobile }}</span>
                        <v-chip small outlined label light :color="'light-green'">{{ item.ctt_email }}</v-chip>
                    </v-list-item-action>
                </v-list-item>
                <v-divider :key="'D-' + index"></v-divider>
            </template>
        </v-list>
        <v-alert v-else color="info" class="ma-2" icon="mdi-alert">Aucun Contact</v-alert>
        </v-card>
    </v-col>
</v-row>

</v-container>
</template>

<script>
import dateLocale       from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
import { usrGetters }   from "../store/user.module"
import { filterUpdateService } from '@/rxjsServices';

export default {
    name: 'dashboard',
    props: ['toast'],
    mixins: [ ],
    data () {
        return {
            uiParams: {
                cardElevation: 'elevation-2'
            },
            loading: { getItem: true, getRessource: false },
            isAuthorized: usrGetters.getScopeRight,
            saveProgress: false,
            dialog: false,
            paginevent: { totalItems: 0 },
            pagindoc  : { totalItems: 0 },
            paginusers: { totalItems: 0 },
            paginalerts: { totalItems: 0 },
            pro_id: 1,
            events: [], alerts: [], info : [], documents: [], users: [], contacts: [],
            locDateFormat: dateLocale, // Format de date
            infoContent: {},
            modalEvent : false,
            eveInfo: { eve_id: 0, eve_sta_id : 'EVE01', eve_table_id: 0, eve_xxx_id  : '' },
            alertSwitch: 50,
            stateState: cacheGetters.getStates(),
            cacheType: cacheGetters.getTypes(),
            typeState: cacheGetters.getTypes(),
        }
    },
    created(){
        this.subscribeFilter = filterUpdateService.getFilter().subscribe(message => {
            if (message) {
                this.getEvent()
                this.getAlert()
                this.getContact()
            }
        })
    },
    mounted:function (){
        this.getEvent()
        this.getAlert()
        this.getContact()
    },
    methods:{
        getEvent(){
            let criteria = encodeURI('eve_sta_id=GEN01&eve_typ_id=%ACT%')
            this.$http.get( '/events/?' + criteria + '&page=1&per_page=4&order_by=eve_id&eve_table_id=cad&order_direction=desc&extended=withcard,withtree' ).then( (response) => {
                this.events = response.data.data
                if( response.data.meta ){
                    this.paginEvent = Object.assign({}, this.paginEvent, response.data.meta.pagination)
                }
            })
        },
        getAlert(){
            let criteria = encodeURI('eve_sta_id=GEN01&eve_typ_id=%ALT%')
            this.$http.get( '/events/?' + criteria + '&page=1&per_page=4&order_by=eve_id&order_direction=desc&extended=withcard,withtree' ).then( (response) => {
                this.alerts = response.data.data
                if( response.data.meta ){
                    this.paginEvent = Object.assign({}, this.paginEvent, response.data.meta.pagination)
                }
            })
        },
        getContact(){
            let criteria = encodeURI('ctt_sta_id=GEN01')
            this.$http.get( '/contacts/?' + criteria + '&page=1&per_page=5' ).then( (response) => {
                this.contacts = response.data.data
                if( response.data.meta ){
                    this.paginEvent = Object.assign({}, this.paginEvent, response.data.meta.pagination)
                }
            })
        },
        openEvent(id, table_id){
            this.eveInfo.eve_id     = id
            this.eveInfo.eve_xxx_id = table_id
            this.switchModal('modalEvent', 1)
        },
        switchModal(name, mode, refresh){
            name    = name || 'modalEvent'
            mode    = mode || 0
            refresh = refresh || false
            this[name] = mode
            if( refresh ){ 
                this.getEvent()
            }
        },
        testEndpoint(){
            //this.$http.get( '/blocks?per_page=false' ).then( (response) => { this.items = response.data.data })
            //this.$http.get( '/cards?per_page=false' ).then( (response) => { this.items = response.data.data })
            //this.$http.get( '/filters?per_page=false' ).then( (response) => { this.items = response.data.data })
            //this.$http.get( '/layersassoc?per_page=false' ).then( (response) => { this.items = response.data.data })
            //this.$http.get( '/listdatas?per_page=false' ).then( (response) => { this.items = response.data.data })
            //this.$http.get( '/listtemplates?per_page=false' ).then( (response) => { this.items = response.data.data })
            //this.$http.get( '/objecttypes?per_page=false' ).then( (response) => { this.items = response.data.data })
            //this.$http.get( '/projects?per_page=false' ).then( (response) => { this.items = response.data.data })
            //this.$http.get( '/projectusers?per_page=false' ).then( (response) => { this.items = response.data.data })
            //this.$http.get( '/projectversions?per_page=false' ).then( (response) => { this.items = response.data.data })
            //this.$http.get( '/themes/?per_page=false' ).then( (response) => { this.items = response.data.data })
            //this.$http.get( '/equipments/?per_page=false' ).then( (response) => { this.items = response.data.data })
            //this.$http.get( '/trees/?per_page=false' ).then( (response) => { this.items = response.data.data })
        },
        getCacheType: cacheGetters.getTypes,
    },
    components: {
        //'apexchart': VueApexCharts //LineChart
    }
}
</script>

<style>
  .small {
    max-width: 600px;
  }
</style>