<template>
  <v-app id="app">
    <v-navigation-drawer 
      width="250" style="z-index:900 !important;"
      mobile-breakpoint="600" fixed
      :mini-variant="miniVariant" :clipped="clipped" v-model="drawer" app hide-overlay dark>
      <v-toolbar flat dense short light color="primary">
        <v-list light class="pa-0">
          <v-list-item light class="pa-0">
            <v-list-item-avatar>
              <v-tooltip bottom open-delay="1500">
                  <template v-slot:activator="{ on }">
                    <img v-on="on" class="hidden-sm-and-up" @click.stop="drawer = !drawer" src="../assets/logo_small.svg" style="cursor:pointer;" />
                    <img v-on="on" class="hidden-xs-only"   @click.stop="miniVariant = !miniVariant" src="../assets/logo_small.svg" style="cursor:pointer; margin-right: 16px;" />
                  </template>
                  <span>Ouvrir / fermer le menu</span>
              </v-tooltip>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title><b>{{title}}</b></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip bottom open-delay="1500">
                  <template v-slot:activator="{ on }">
                      <v-icon v-on="on" class="pt-1 pr-1 hidden-sm-and-up" @click.stop="drawer = !drawer">mdi-backburger </v-icon>
                  </template>
                  <span>Ouvrir / fermer le menu</span>
              </v-tooltip>
<!--              <v-tooltip bottom open-delay="1500">
                  <template v-slot:activator="{ on }">
                      <v-icon v-on="on" class="pt-1 pr-1 hidden-xs-only" @click.stop="miniVariant = !miniVariant">mdi-backburger</v-icon>
                  </template>
                  <span>Ouvrir / fermer le menu</span>
              </v-tooltip>              
-->
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list dark dense>
        <template v-for="(item, i) in items" >
          <v-list-group v-if="item.active && item.items" :key="i" v-bind:group="item.group" :prepend-icon="item.icon" style="width:100%;">
            <template v-slot:activator>
            <v-subheader v-if="item.header && !miniVariant" :key="i" class="pl-0 theme--dark" style="margin-right:auto;">
              {{ item.header }}
            </v-subheader>
            <v-list-item v-else-if="!miniVariant" :to="item.href" :title="item.title" class="pa-0">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
            <v-subheader v-else :title="item.title" class="pl-0 theme--dark">
              <v-icon dark>{{ item.icon }}</v-icon>
            </v-subheader>
            </template>
            <!-- Sous menus -->
            <v-list-item v-for="subItem in item.items" :key="subItem.href" :to="subItem.href" :title="subItem.title"
                        v-bind:router="!subItem.target" ripple
                        v-bind:disabled="subItem.disabled" v-bind:target="subItem.target" >
              <v-list-item-action v-if="subItem.active && subItem.icon" >
                <v-icon dark>{{ subItem.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content v-if="subItem.active">
                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <!-- Divider -->
          <v-divider v-else-if="item.active && item.divider" :key="i"></v-divider>
          <!-- Sous titre -->
          <v-subheader v-else-if="item.active && item.header && !miniVariant" :key="i" class="theme--dark">{{ item.header }}</v-subheader>
          <v-list-item v-else-if="item.active && !item.header" :key="i" :to='item.href' :title="item.title">
            <v-list-item-action>
              <v-icon dark>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dense color="white" light short elevation="1" style="z-index:100 !important;">
      <v-tooltip bottom open-delay="1500">
          <template v-slot:activator="{ on }">
              <v-btn :ripple="false" text v-on="on" @click.stop="switchMenu()">
              <v-icon class="pt-1 pr-1">mdi-backburger</v-icon>
              </v-btn>
          </template>
          <span>Ouvrir / fermer le menu</span>
      </v-tooltip>  
      <v-menu v-model="filterMenu" :close-on-content-click="false" :nudge-width="300" offset-y z-index="900" content-class="white rounded-b-lg">
        <template v-slot:activator="{ on: filterMenu }">
          <v-tooltip right open-delay="1500" >
              <template v-slot:activator="{ on: tooltip }">
                <v-badge :content="filterCount" :value="filterCount" color="red" overlap >
                  <v-btn text v-on="{ ...tooltip, ...filterMenu }">
                      <v-icon >mdi-filter-outline</v-icon>
                  </v-btn>
                </v-badge>
              </template>
              <span>Filtre général</span>
          </v-tooltip>
        </template>
        <view-filter class="mt-2" style="z-index:900 !important;" :filterDiff="filterDiff" :filterCount.sync="filterCount" :filterView.sync="filterMenu"></view-filter>
      </v-menu>
<!--      <v-menu v-model="siteMenu" :close-on-content-click="false" :nudge-width="600" offset-y z-index="1100">
        <template v-slot:activator="{ on: menu }">
          <v-tooltip right open-delay="500">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn v-on="{ ...tooltip, ...menu }" icon slot="activator" v-show="siteTabBt">
                  <v-badge color="red" class="mt-1" :content="bookmarkCount" :value="bookmarkCount">
                    <v-icon>mdi-card-bulleted-outline</v-icon>
                  </v-badge>
                </v-btn>
              </template>
              <span>Voir les fiches en favoris</span>
          </v-tooltip>
        </template>
        <view-opencard class="mt-2" style="z-index:900 !important;" :tabsheetCount="bookmarkCount" :menuState="openCardState"></view-opencard>
      </v-menu>
-->
      <v-select dense solo
        :items="projects" v-model="user.usr_pro_id" @change="switchProject"
        item-text="pro_label" item-value="pro_id" 
        :menu-props="{ 'z-index': 1500 }"
        style="max-width:200px; z-index:1500;" class="ml-5 mt-6">
      </v-select>
      <v-spacer></v-spacer>
      <v-menu v-model="alertMenu" :close-on-content-click="false" :nudge-width="250" offset-y z-index="1100">
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom open-delay="1500">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn v-on="{ ...tooltip, ...menu }" icon slot="activator" v-show="alertBelt">
                  <v-badge color="red" class="mt-1" :content="alertCount" :value="alertCount">
                    <v-icon>mdi-bell</v-icon>
                  </v-badge>
                </v-btn>
              </template>
              <span>Liste des alertes</span>
          </v-tooltip>
        </template>
        <view-openalert class="mt-2" style="z-index:900 !important;" :filterDiff="filterDiff" :menuState="openCardState"></view-openalert>
      </v-menu>
      <!-- Versionning -->
      <v-btn text style="text-transform: capitalize;" @click="dialChangelog = !dialChangelog">
        <v-icon left >mdi-information-outline</v-icon>
        {{ $APP_VER }}
      </v-btn>
      <v-menu v-model="userMenu" offset-y transition="slide-x-transition" bottom left content-class="elevation-0" style="z-index:900">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" style="text-transform: capitalize;">
            <v-avatar><v-icon dark class="ml-0">mdi-account-circle</v-icon></v-avatar>
            {{user.usr_firstName}}
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-btn @click="logout()">
          <v-icon left>mdi-logout-variant</v-icon>Se déconnecter
        </v-btn>
      </v-menu>
    </v-app-bar>
    <!-- Router view page content -->
    <v-main v-if="dataReady" style="background-color:#f6f8f8; position: relative;">
      <router-view :toast="toast" :filterView="filterMenu"></router-view>
    </v-main>
    <!-- Router view slider -->
    <ToastDialog ref="toast" />
    <router-view v-if="dataReady" name="modal" :toast="toast" :siteTabState="siteTabState" :filterView="filterMenu"></router-view>
    <confirm ref="confirm"></confirm>
    <view-changelog :diagState.sync="dialChangelog" style="z-index:2000;"></view-changelog>
    <v-snackbar style="z-index:2000;"
      :timeout="2000" :color="toastOption.color"
      :top="toastOption.top" :bottom="toastOption.bottom" 
      :left="toastOption.left" :right="toastOption.right" v-model="enableToast" >
        {{toastOption.text}}
    </v-snackbar>
  </v-app>
</template>

<script>
import { actions } from "../store/auth.module"
import menu         from '../confnav'
import { cacheData, cacheMutations, cacheGetters, cacheActions } from "../store/cache.module"
import vFilter      from '../components/View_filter.vue'
//import vOpencard    from '../components/View_openedcard.vue'
//import vOpenalert   from '../components/View_alert.vue'
import vChangelog   from '../components/View_changelog.vue'
import confirmDial  from '../components/Confirm_dialog.vue'
import { filterUpdateService } from '@/rxjsServices';
import ToastDialog  from '@/components/ui/Toast.vue'

export default {
  name: 'mainApp',
  //props: ['msg'],
components: {
    ToastDialog, 'view-filter'   : vFilter, 'confirm' : confirmDial, 'view-changelog' : vChangelog },
  data () {
    return {
        clipped: false,
        drawer: false,
        fixed: false,
        items: [],
        miniVariant: false,
        right: true,
        rightDrawer: false,
        title: process.env.VUE_APP_TITLE,
        option: {},
        enableToast: false,
        toastDefault: { color: '', top:false, bottom:true, right:true, left:false, text:'Fiche enregistré' },
        toastOption:  { color: '', top:false, bottom:true, right:true, left:false, text:'Fiche enregistré' },
        filterMenu: false, 
        siteMenu: false, 
        siteTabBt: true,
        alertBelt: false,
        userMenu: false,
        alertMenu: false,
        dataReady: false,
        filterCount: 0,
        filterDiff: 0,
        alertCount: 0,
        tabsheetCount: 0,
        dialChangelog: false,
        projects: [],
        user: {}
    }
  },
  computed: {
      //user(){
      //  return cacheData.user
      //},
      bookmarkCount(){
          var total = 0
          var item
          for( item in cacheData.bookmark ){
              if( item ){
                  total++
              }
          }
          return total
      }
  },
  beforeMount: function(){
    this.initCache()
  },
  mounted: function (){
    // Global Confirm Dialog
    this.$root.$confirm = this.$refs.confirm.open
    this.$root.$toast   = this.$refs.toast.open
    this.projects = cacheGetters.getProjects()
    this.user     = cacheGetters.getUser()
  },
  methods: {
    initCache(){
      //Chargement du cache
      cacheActions.initCacheFromStorage().then( () => {
          Promise.all([ 
            this.getType(), this.getState(), this.getUserInfo(), this.getObjectType(), this.getTheme(), this.getProjectVersion(),
            this.getLists(), this.getListsData(), this.getProjects(), this.getCardTemplate()
          ]).then( () => {
            cacheMutations.setStates( cacheGetters.getStates() )
            cacheMutations.setTypes( cacheGetters.getTypes() )
            cacheMutations.setUser( cacheGetters.getUser() )
            cacheMutations.setObjectType( cacheGetters.getObjectType() )
            cacheMutations.setTheme( cacheGetters.getTheme() )
            cacheMutations.setVersion( cacheGetters.getVersion() )
            cacheMutations.setLists( cacheGetters.getLists() )
            cacheMutations.setListsData( cacheGetters.getListsData() )
            cacheMutations.setProjects( cacheGetters.getProjects() )
            cacheMutations.setCardTemplate( cacheGetters.getCardTemplate() )
            cacheActions.storeCacheToStorage() //this.$storage.localStorage.set( this.$APP_COD + "_cache", cacheData)
          }).then( () => {
            this.buildMenu()
            this.dataReady = true
            if( this.$route.path == '/forbidden' ){
              this.$router.push('/dashboard')
            }
          }).catch( () => {
            this.buildMenu()
            this.dataReady = true
          })
      })
    },
    buildMenu(){
      // Right on menu by role
      let usrRole  = cacheGetters.getUserRole()
      menu.menu.forEach( (element) => {
        element.active = true
        if( element.role !== undefined && element.role.indexOf( usrRole ) === -1 ){
            element.active = false //menu.menu.splice( menu.menu.indexOf(element), 1 )
        } else if( element.items !== undefined ){
          element.items.forEach( (item) => {
            item.active = true
            if( item.role !== undefined && item.role.indexOf( usrRole ) === -1 ){
                item.active = false
                element.items.splice( element.items.indexOf( item ), 1 )
            }
          })
        }
      })
      this.items = menu.menu
      //this.items = ctrlMenu
      if( window.innerWidth > 600 ){
        this.miniVariant = false
        this.drawer      = true
      }
    },
    getType () {
      return new Promise( (resolve, reject) => {
        this.$http.get( '/types?per_page=false' ).then( (response) => {
          let types = {}
          response.data.data.forEach( (item) => {
            if( types[ item.typ_id.substring(0, 3) ] == undefined ){
              types[ item.typ_id.substring(0, 3) ] = {}
            }
            types[ item.typ_id.substring(0, 3)][item.typ_id] = item
          })
          cacheMutations.setTypes( types )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getState () {
      return new Promise( (resolve, reject) => {
        this.$http.get( '/states?per_page=false' ).then( (response) => {
          let states = {}
          response.data.data.forEach( (item) => {
            if( states[ item.sta_id.substring(0, 3) ] == undefined ){
              states[ item.sta_id.substring(0, 3) ] = {}
            }
            states[ item.sta_id.substring(0, 3) ][item.sta_id] = item
          })
          cacheMutations.setStates( states )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getUserInfo(){
      return new Promise( (resolve, reject) => {
        this.$http.get( '/users/me' ).then( (response) => {
          cacheMutations.setUser( response.data.data )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getObjectType () {
      return new Promise( (resolve, reject) => {
        this.$http.get( '/objecttypes?per_page=false' ).then( (response) => {
          cacheMutations.setObjectType( response.data.data )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getTheme () {
      return new Promise( (resolve, reject) => {
        this.$http.get( '/themes?per_page=false' ).then( (response) => {
          cacheMutations.setTheme( response.data.data )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getProjectVersion () {
      return new Promise( (resolve, reject) => {
        this.$http.get( '/projectversions?per_page=false' ).then( (response) => {
          cacheMutations.setVersion( response.data.data )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getCardTemplate () {
      return new Promise( (resolve, reject) => {
        this.$http.get( '/cardtemplates?per_page=false' ).then( (response) => {
          cacheMutations.setCardTemplate( response.data.data )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getLists () {
      return new Promise( (resolve, reject) => {
        this.$http.get( '/listtemplates?per_page=false' ).then( (response) => {
          cacheMutations.setLists( response.data.data )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getListsData () {
      return new Promise( (resolve, reject) => {
        this.$http.get( '/listdatas?per_page=false&order_by=lsd_label&order_direction=asc' ).then( (response) => {
          cacheMutations.setListsData( response.data.data )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    getProjects () {
      return new Promise( (resolve, reject) => {
        this.$http.get( '/projects/?per_page=false' ).then( (response) => {
          cacheMutations.setProjects( response.data.data )
          resolve('resolved');
        }).catch( () => { 
          reject('failed');
        })
      })
    },
    //getBlocks () {
    //  return new Promise( (resolve, reject) => {
    //    this.$http.get( '/Blocks?per_page=false' ).then( (response) => {
    //      cacheMutations.setTaskType( response.data.data )
    //      resolve('resolved');
    //    }).catch( () => { 
    //      reject('failed');
    //    })
    //  })
    //},
    toast (option) {
      if( option !== null && typeof option === 'object' ){
        var prop
        for( prop in this.toastDefault ){
            if( option[prop] !== undefined ){
                this.toastOption[prop] = option[prop]
            }
        }
      }
      this.enableToast = true
    },
    hideToast () {
      this.snackbar = false
    },
    updateFilter(num){
      this.filterDiff = num
    },
    openCardState(state){
      this.siteMenu = state
    },
    siteTabState(state){
      this.siteTabBt = state
    },
    logout(){
      if( process.env.VUE_APP_OAUTH_IDP === 'true' ){
        actions.logout().then( () => {
          this.$router.push({ path: '/login' })
        })
      } else {
        actions.logout().then( () => {
          const uri = `?returnTo=${encodeURIComponent( process.env.VUE_APP_URL + '#/login' )}`
          let samlIpd = process.env.VUE_APP_SAML_IDP || 'test'
          window.location = process.env.VUE_APP_API_URL + 'saml2/' + samlIpd + '/logout' + uri
        })
      }
    },
    switchMenu(){
      if( this.$vuetify.breakpoint.width < 600 ){
        this.drawer     = !this.drawer
      } else {
        this.miniVariant = !this.miniVariant
      }
    },
    async switchProject(id) {
      await this.$http.put( '/users/' + this.user.usr_id, { usr_pro_id: id } )
      this.initCache()
      filterUpdateService.sendFilter({
          filterObject: cacheGetters.getFilter(),
          urlTask: cacheGetters.getFilterUrl()
      })
    },
  }
}
</script>
